// NB: This module is being imported just to use the TypeScript types; the library is actually being loaded from a CDN.
// TODO: Need to find a way to not have to use the CDN and use it locally.
import { Chart } from 'chart.js';

export class ChartJsInterop
{
	public setTickCallback(chartId: string): void
	{
		const chart = (window as any).Chart.getChart(chartId) as Chart;

		if (chart === null || chart === undefined)
		{
			return;
		}

		if (chart.options.scales?.y?.ticks)
		{
			chart.options.scales.y.ticks.callback = (tickValue, index, ticks) =>
			{
				return `\u00A3${tickValue.toLocaleString()}`;
			};
		}

		chart.update();
	}

	public setTooltipCallback(chartId: string): void
	{
		const chart = (window as any).Chart.getChart(chartId) as Chart;

		if (chart === null || chart === undefined)
		{
			return;
		}

		if (chart.options?.plugins?.tooltip)
		{
			// Create a shallow copy of the current tooltip callbacks or initialize it as an empty object
			const tooltipCallbacks = { ...chart.options.plugins.tooltip.callbacks };

			// Define the label callback to avoid circular references or infinite loops
			tooltipCallbacks.label = (context) =>
			{
				const label = context.dataset.label || '';
				const value = context.parsed.y || 0;
				return `${label}: \u00A3${value.toLocaleString()}`;
			};

			// Assign the modified callbacks back to the tooltip options
			chart.options.plugins.tooltip.callbacks = tooltipCallbacks;
		}

		chart.update();
	}

	public scrollToChartCallback(): void
	{
		const section = document.querySelector(".isa-calculator__results");
		if (section)
		{
			(section as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
		} else
		{
			console.warn("Results section not found.");
		}
	}
}